.accordion {
  // background-color: aliceblue;
  color: #fff;
}

.accordionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 15px;
  padding-top: 15px;
  &_title {
    display: flex;
    align-items: center;
  }
}

.subHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
