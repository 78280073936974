@import '../../constants/colors.scss';

.sidebar {
  width: 240px;
  min-width: 240px;
  min-height: 100vh;
  overflow: hidden;
  transition: min-width 0.3s ease, width 0.3s ease;
  border: 1px solid #393c47;
  box-sizing: border-box;
  // position: relative;

  &_min {
    // width: calc(24px * 2 + 20px);
    min-width: 64px;
    width: 64px;
  }

  &__min-max {
    cursor: pointer;
    // background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 100px 20px 20px;
    transition: margin 0.3s ease;

    &:hover {
      // background-color: $dark;
    }

    &--center {
      margin: 10px 0 20px 20px;
    }

    &__img {
      transition: transform 0.3s ease;
    }

    &__img--close {
      transform: rotate(180deg);
    }
  }

  // &__arrow-img {
  //   display: block;
  //   margin: auto;
  // }
}
