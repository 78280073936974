@import '../../constants/colors.scss';

.modal-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
  & h1 {
    color: $dark;
  }
}
