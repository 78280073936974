@import '../../../constants/colors.scss';

.input {
  padding: 12px 0px 12px 42px;
  width: -webkit-fill-available;
  border-radius: 4px;
  border: 1px solid #393c47;
  max-width: 510px;
  background-color: $card-dark;
  font-family: 'Inner';
  font-size: 15px;
  color: #828d99;

  &:focus {
    outline: 1px solid $light-grey;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;
}

.searchIcon {
  position: absolute;
  left: calc(42px / 2 - 18px / 2);
  cursor: pointer;
}
