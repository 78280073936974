@import '../../../constants/colors.scss';

.sidebar__item {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  cursor: pointer;
  padding: 8px 24px;
  transition: 0.3 ease background-color;
  &:hover {
    background-color: #000;
  }

  &--selectable {
    background-color: #000;
  }

  &__img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__title {
    padding: 0;
    margin: 0;
  }
}
