@import '../../constants/colors.scss';

.table {
  padding: 0 40px;
  border-spacing: 0;
  width: 100%;
  &-row {
    cursor: pointer;
    transition: background-color 0.1s ease;

    &:hover {
      background-color: #13161b;
    }
  }

  th {
    padding: 17px 15px;
    color: $light-grey;
    font-size: 12px;
    text-align: left;

  }
}

.table-item {
  padding: 24px 15px;
  font-weight: 400;
  color: $light-grey;
    border-top: 1px solid #393C47;

  &_title {
  }

  &--bold {
    font-weight: 600;
    color: #fff;
  }

  &--link {
    font-weight: 500;
    color: $light-blue;
  }
}
