@import '../../constants/colors.scss';
.navigationPanelItem {
  width: fit-content;
}

.navigationPanelTitle {
  margin-bottom: 22px;
  cursor: pointer;
}
.navigationPanelTitleSelected {
  color: $blue;
}
.navigationPanelTitleDisabled {
  color: $dark-grey;
}
