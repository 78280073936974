@import '../../../constants/colors';

.drag-documents {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  border: 1px dashed #656870;
  background-color: $dark;
  cursor: pointer;

  &__image {
    margin-right: 15px;
  }
}

.drag-documents-link {
  font-family: 'InnerBold';
  font-size: 12px;
  color: $light-grey;
  text-transform: uppercase;
  color: $blue;
  margin: 0px 3px;
}

.cross-button {
  all: unset;
  cursor: pointer;
  padding-left: 13px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  justify-content: center;
  display: flex;

  &_item {
    align-self: center;
    background-color: $light-grey;
    width: 1px;
    height: 100%;
    border-radius: 1px;
    transform: rotate(45deg);
  }
  &_item-second {
    align-self: center;
    background-color: $light-grey;
    width: 1px;
    height: 100%;
    border-radius: 1px;
    transform: rotate(-45deg);
    position: relative;
    left: -1px;
  }
}
