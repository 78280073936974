@import './constants/colors.scss';

.page-with-sidebar {
  display: flex;
}

.page-container {
  padding: 22px 36px;
  height: calc(100vh - 44px);
  overflow: auto;
  width: -webkit-fill-available;
}

.shadow-container {
  box-shadow: -8px 12px 18px rgba(0, 0, 0, 0.128567);
}

.auth {
  width: 330px;
  height: 430px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 15vh;
  padding: 0 50px;
  background-color: #1e2127;
  border-radius: 2px;
}

.info-card-container {
  width: 100%;
  max-width: 700px;
  margin: auto;

  &__flex {
    display: flex;
    align-items: center;
  }
}

.info-card {
  width: 100%;
  border-radius: 10px;
  padding: 20px;

  &__field:not(:first-child) {
    margin-top: 20px;
  }

  &__flex-filed-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
  }

  &__field {
    position: relative;

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  &__field-wrapper {
    margin-bottom: 20px;
    min-width: 200px;
    flex: 1;
    flex-wrap: nowrap;
  }
}

.input {
  color: #fff;
  border: none;
  background-color: inherit;
  border: 1px solid #35383f;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 12px;
  outline: none;
  width: 100%;

  &:focus {
    border: 1px solid $blue;
  }

  &--disabled {
    border: none;
    background-color: $dark;
  }

  &--error {
    border: 1px solid $extra-red;
  }

  &-label__text {
    margin-bottom: 5px;
  }

  &__label--error {
    font-size: 10px;
    font-family: 'InnerBold';
    color: $extra-red;
    margin: 2px;
    position: absolute;
  }

  &__label-title {
    font-family: 'InnerBold';
    font-size: 12px;
    color: $light-grey;
    text-transform: uppercase;
  }

  &--small {
    width: 126px;
  }

  &--medium {
    width: 276px;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: $border-gey;
  margin-top: 24px;
  margin-bottom: 24px;
}

.selectable-line {
  height: 0;
  background-color: $blue;
  margin: 0;

  &--selected {
    height: 2px;
  }
}
