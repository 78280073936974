$grey: #a8acb7;
$light-grey: #bec2cc;
$dark-grey: #4b515e;
$dark: #1b1d24;
// $light-dark: #23242c;
$card-dark: #1e2127;
$extra-dark: #13161b;

$red: #ff4a7a;
$extra-red: #ff0000;
$dark-red: #dd1a4e;
$purple: #c27aeb;
$dark-purple: #9821dd;
$yellow: #ffdd00;
$dark-yellow: #c9b539;
$green: #73d500;
$dark-green: #62a515;
$blue: #017dd9;
$dark-blue: #004f8b;
$light-blue: #117dce;
$extra-light-blue: #03c4c6;

$blue-text: #377dce;
$light-grey: #828d99;

$border-gey: #393c47;
