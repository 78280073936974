@import './constants/colors.scss';

@font-face {
  font-family: 'Inner';
  src: url('./assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'InnerBold';
  src: url('./assets/fonts/Inter-Bold.ttf');
}
@font-face {
  font-family: 'InnerExtraBold';
  src: url('./assets/fonts/Inter-ExtraBold.ttf');
}

body {
  margin: 0;
  font-family: 'Inner';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// disable arrows for number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

p,
h1,
h2,
h3,
h4,
span,
td,
th {
  color: #fff;
}

.no-margin {
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

// START FONTS

.h {
  // font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
}

.h1 {
  @extend .h;
  font-size: 38px;
}

.h2 {
  @extend .h;
  font-size: 32px;
}

.h3 {
  @extend .h;
  font-size: 20px;
}

.h4 {
  font-family: 'InnerExtraBold';
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.h4_d {
  // description
  @extend .h4;
  color: $light-grey;
}

.h4_i {
  // info
  @extend .h4;
  color: $blue-text;
}

.body-text {
  // font-family: "Archivo";
  font-style: normal;
  font-weight: normal;
}

.body-text-1 {
  @extend .body-text;
  font-size: 18px;
}

.body-text-1-b {
  // bold
  @extend .body-text;
  font-weight: 500;
  font-size: 18px;
}

.body-text-1-i {
  // info
  @extend .body-text;
  font-weight: 500;
  font-size: 18px;
  color: $blue-text;
}

.body-text-2 {
  @extend .body-text;
  font-size: 15px;
}

.body-text-2_b {
  // bold
  @extend .body-text-2;
  // font-weight: 700;
  font-family: InnerBold;
}

.body-text-2_d {
  // description
  @extend .body-text-2;
  color: $light-grey;
}

.body-text-2_i {
  // info
  @extend .body-text-2;
  color: $blue-text;
}

.body-text-3 {
  @extend .body-text;
  font-size: 13px;
}

.body-text-3_b {
  // bold
  @extend .body-text-3;
  font-weight: 600;
  text-transform: uppercase;
}

.body-text-3_d {
  // description
  @extend .body-text-3;
  color: $light-grey;
}

.body-text-3_i {
  // info
  @extend .body-text-3;
  color: $blue-text;
}

.body-text-3_c {
  // description
  @extend .body-text-3;
  // color: $light-grey;
  text-transform: uppercase;
}

body-text-3_j {
  // bold
  @extend .body-text-3;
  font-weight: 600;
}

.tiny-text {
  @extend .body-text;
  font-size: 10px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.tiny-text_d {
  // description
  @extend .tiny-text;
  color: $light-grey;
}

.tiny-text_i {
  // info
  @extend .tiny-text;
  color: $light-grey;
}

// END FONTS

// START BACKGROUND COLORS
.bg-yellow {
}

// END BACKGROUND COLORS
