.arrowWrapper {
  background-color: #000000;
  width: 18px;
  height: 18px;
  align-self: center;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
  }
}
.arrowWrapper-left {
  @extend .arrowWrapper;
  margin-right: 10px;
  margin-left: 10px;
  transform: rotate(180deg);
}
