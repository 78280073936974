@import '../../../constants/colors.scss';

.form-container {
  background-color: #1e2127;
  padding: 24px 65px;
  border-radius: 4px;
  overflow-y: auto;
  max-height: calc(
    100vh - 44px - 167px - 65px
  ); // window height - page padding - header heigth - own padding
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
